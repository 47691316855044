import http, { authHeader } from '../http-common';
import axios from 'axios';

export const countOrders = async ({ mode, from, to }) => {
  const headers = authHeader();

  try {
    let myRes = await axios.get(
      `${http.defaults.baseUrl}/countOrders?mode=${mode}&from=${from}&to=${to}`,
      { headers }
    );

    if (myRes.status === 200) {
      return myRes.data.count;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getOrders = async ({ mode, from, to, limit, skip }) => {
  const headers = authHeader();
  let uri = `mode=${mode}&from=${from}&to=${to}&limit=${limit}&skip=${skip}`;
  let myURI = `${http.defaults.baseUrl}/orders?${uri}`;
  let myRes = await axios.get(myURI, { headers });
  return { orders: myRes.data.orders };
};

// get monthly sums
export const getMonthlyTotals = async ({ from, to }) => {
  const headers = authHeader();
  let uri = `from=${from}&to=${to}`;
  let myURI = `${http.defaults.baseUrl}/monthlyTotals?${uri}`;
  let myRes = await axios.get(myURI, { headers });
  return { sums: myRes.data.sums };
};

export const sendInvoice = async orderId => {
  const headers = authHeader();
  let uri = `orderId=${orderId}`;
  let myURI = `${http.defaults.baseUrl}/sendInvoice?${uri}`;
  let myRes = await axios.get(myURI, { headers });
  //  return myRes.data.orders;
};

export const downloadInvoice = invoiceNo => {
  const headers = authHeader();
  let uri = `invoiceNo=${invoiceNo}`;
  let myURI = `${http.defaults.baseUrl}/invoice?${uri}`;
  axios({
    url: myURI,
    headers: headers,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${invoiceNo}.pdf`);
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadMonthlyInvoices = ({ month, year }) => {
  const headers = authHeader();
  let startDate = new Date(year, month, 1);
  let endDate = new Date(year, month + 1, 0);
  let uri = `startDate=${startDate}&endDate=${endDate}`;
  let myURI = `${http.defaults.baseUrl}/invoices?${uri}`;
  axios({
    url: myURI,
    headers: headers,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    let myDate = new Date(year, month, 1);
    const options = { year: 'numeric', month: 'long' };

    // console.log(event.toLocaleDateString('de-DE', options));
    link.setAttribute(
      'download',
      `${myDate.toLocaleDateString('de-DE', options)}.pdf`
    );
    document.body.appendChild(link);
    link.click();
  });
};

export const downloadMonthlyInvoicesCSV = ({ month, year }) => {
  const headers = authHeader();
  let startDate = new Date(year, month, 1);
  let uri = `startDate=${startDate}&type=csv`;
  let myURI = `${http.defaults.baseUrl}/invoices?${uri}`;
  axios({
    url: myURI,
    headers: headers,
    method: 'GET',
    responseType: 'blob', // important
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    let myDate = new Date(year, month, 1);
    const options = { year: 'numeric', month: 'long' };

    link.setAttribute(
      'download',
      `${myDate.toLocaleDateString('de-DE', options)}.csv`
    );
    document.body.appendChild(link);
    link.click();
  });
};

export const getDocs = async (filter, docsPerPage, skip) => {
  const headers = authHeader();
  const { category, labelStatus, searchTerm } = filter;
  let productId = 'alle';
  if (category.shopifyProductId) {
    productId = category.shopifyProductId;
  }
  try {
    let mySearchTerm = encodeURIComponent(searchTerm);
    let uri = `productId=${productId}&searchTerm=${mySearchTerm}&status=${labelStatus}&limit=${docsPerPage}&skip=${skip}`;
    // let myURI = encodeURIComponent(uri);
    let myURI = `${http.defaults.baseUrl}/orders?${uri}`;
    // console.log(myURI);
    let myRes = await axios.get(myURI, { headers });

    if (myRes.status === 200) {
      let myDocs = [...myRes.data];
      myDocs.sort((a, b) => (a.date < b.date ? 1 : b.date < a.date ? -1 : 0));
      myDocs.forEach(d => (d.markForDownload = false));
      return myDocs;
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrder = async id => {
  const headers = authHeader();
  const data = {
    id: id,
  };

  try {
    let myRes = await http.delete(`${http.defaults.baseUrl}/order`, {
      headers,
      data,
    });
    if (myRes.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

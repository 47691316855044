import React from 'react';
import UserTableRow from './UserTableRow';

const UserTableContent = ({ users, setUsers }) => {
  return (
    <div className='table'>
      <div className='table-inner'>
        {users.map((user, index) => (
          <UserTableRow
            key={user._id}
            user={user}
            users={users}
            index={index}
            setUsers={setUsers}
          />
        ))}
      </div>
    </div>
  );
};

export default UserTableContent;

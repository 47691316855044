import React, { useState, useContext } from 'react';
import { VERSION } from '../../includes/constants';
import UserContext from '../../context/UserContext';
import { loginUser } from '../../routes/user-routes';

const SignInCard = () => {
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [showError, setShowError] = useState(false);

  const { setActiveUser } = useContext(UserContext);

  const performLogin = async e => {
    e.preventDefault();

    let myRes = await loginUser({ name, password, setActiveUser });
    if (!myRes) {
      setShowError(true);
      setTimeout(() => {
        setShowError(false);
      }, 3000);
    }
  };

  return (
    <div className='card'>
      <div className='row flex-left'>
        <i className='far fa-user-circle fa-lg mr-8'></i>
        <span className='bold'>Bitte anmelden</span>
      </div>
      <form onSubmit={e => performLogin(e)}>
        <div className='row'>
          <label className='bold'>Login</label>
          <input
            type='text'
            value={name}
            onChange={e => setName(e.target.value)}
            className={showError ? 'error' : ''}
          />
        </div>
        <div className='row'>
          <label className='bold'>Passwort</label>
          <input
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={showError ? 'error' : ''}
          />
        </div>
        <div className='row'>
          <input type='submit' className='primary' value='Anmelden' />
        </div>
      </form>
      {/* <div>{activeUser.user && activeUser.user.name}</div> */}
      <div className='card-footer'>
        <div className='row'>
          <span>&#169; BlickSolutions GmbH</span>
          <span>Version {VERSION}</span>
        </div>
      </div>
    </div>
  );
};

export default SignInCard;

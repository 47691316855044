import React from 'react';
import Dropdown from 'react-dropdown';

const arrowClosed = (
  <div className='arrow-closed'>
    <i className='fas fa-chevron-up'></i>
  </div>
);
const arrowOpen = (
  <div className='arrow-open'>
    <i className='fas fa-chevron-up'></i>
  </div>
);

const RoleSelect = ({ value, onChange }) => {
  const options = [
    { value: 'readOnly', label: 'Nur Lesen' },
    {
      value: 'readWrite',
      label: 'Lesen & Schreiben',
    },
    { value: 'admin', label: 'Administrator' },
  ];

  return (
    <Dropdown
      options={options}
      value={value}
      onChange={onChange}
      name='role'
      className='role'
      arrowClosed={arrowClosed}
      arrowOpen={arrowOpen}
    />
  );
};

export default RoleSelect;

import React, { useState, useEffect } from 'react';
import OrdersTableHeader from './OrdersTableHeader';
import OrdersTableContent from './OrdersTableContent';
import Pagination from '../Pagination';
import SumTableRow from './SumTableRow';

import {
  deleteOrder,
  countOrders,
  getOrders,
  sendInvoice,
  getMonthlyTotals,
  downloadMonthlyInvoices,
  downloadMonthlyInvoicesCSV,
} from '../../routes/order-routes';
// import { downloadMonthlyInvoices } from '../../routes';
import { ROWS_PER_PAGE } from '../../includes/constants';

const OrdersTable = ({ setOpenOrders }) => {
  const [activeFilter, setActiveFilter] = useState('open');

  const [monthYear, setMonthYear] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const [totalSums, setTotalSums] = useState({ net: 0, tax: 0, gross: 0 });

  const [docs, setDocs] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  const loadMyDocs = async () => {
    return await getOrders({
      mode: activeFilter,
      from: new Date(monthYear.year, monthYear.month, 1, 0, 0, 0, 0),
      to: new Date(monthYear.year, monthYear.month + 1, 0, 0, 0, 0, 0),
      skip: activePage * ROWS_PER_PAGE,
      limit: ROWS_PER_PAGE,
    });
  };

  const loadMyTotals = async () => {
    return await getMonthlyTotals({
      from: new Date(monthYear.year, monthYear.month, 1, 0, 0, 0, 0),
      to: new Date(monthYear.year, monthYear.month + 1, 0, 0, 0, 0, 0),
    });
  };

  useEffect(() => {
    const getTotals = async () => {
      let { sums } = await loadMyTotals();
      setTotalSums(sums);
    };
    getTotals();
  }, [monthYear]);

  useEffect(() => {
    let skip = activePage * ROWS_PER_PAGE;

    const loadDocs = async () => {
      // let { orders } = await getOrders({
      //   mode: activeFilter,
      //   from: new Date(monthYear.year, monthYear.month, 1, 0, 0, 0, 0),
      //   to: new Date(monthYear.year, monthYear.month + 1, 0, 0, 0, 0, 0),
      //   skip: skip,
      //   limit: ROWS_PER_PAGE,
      // });
      setDocs([]);
      let { orders } = await loadMyDocs();
      setDocs(orders);
    };

    const loadNoOfDocs = async () => {
      let openOrdersCount = await countOrders({
        mode: 'open',
        from: new Date(2021, 0, 1, 0, 0, 0, 0),
        to: new Date(),
      });

      setOpenOrders(openOrdersCount);

      let ordersCount = await countOrders({
        mode: activeFilter,
        from: new Date(monthYear.year, monthYear.month, 1, 0, 0, 0, 0),
        to: new Date(monthYear.year, monthYear.month + 1, 0, 0, 0, 0, 0),
      });
      let myTotalPages = Math.ceil(ordersCount / ROWS_PER_PAGE);
      if (isNaN(myTotalPages)) myTotalPages = 0;
      setTotalPages(myTotalPages);
      if (activePage > myTotalPages) setActivePage(0);
    };
    loadDocs();
    loadNoOfDocs();
  }, [activeFilter, activePage, setOpenOrders, monthYear]);

  const sendTheInvoice = async orderId => {
    await sendInvoice(orderId);
    let { orders } = await loadMyDocs();
    setDocs(orders);
    let { sums } = await loadMyTotals();
    setTotalSums(sums);
  };

  const removeOrder = async id => {
    await deleteOrder(id);
    let { orders } = await loadMyDocs();
    setDocs(orders);
    let { sums } = await loadMyTotals();
    setTotalSums(sums);
    // if (myRes) {
    //   getAndSetOpenOrders();
    //   updateDocs();
    // }
    // let myNoOfTotalLabels = await countOrders({
    //   labelStatus: 'alle',
    //   category: 'alle',
    // });
    // setTotalLabels(myNoOfTotalLabels);
  };

  return (
    <div className='table-wrapper'>
      <OrdersTableHeader
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        monthYear={monthYear}
        setMonthYear={setMonthYear}
      />
      <OrdersTableContent
        docs={docs}
        sendInvoice={sendTheInvoice}
        removeOrder={removeOrder}
      />
      {activeFilter === 'archiv' && (
        <SumTableRow
          totalSums={totalSums}
          downloadMonthlyInvoices={() => downloadMonthlyInvoices(monthYear)}
          downloadMonthlyInvoicesCSV={() =>
            downloadMonthlyInvoicesCSV(monthYear)
          }
        />
      )}
      {totalPages > 1 && (
        <Pagination
          activePage={activePage}
          setActivePage={setActivePage}
          totalPages={totalPages}
        />
      )}
    </div>
  );
};

export default OrdersTable;

import React from 'react';
import { VERSION } from '../includes/constants';

const test = 1;

const Footer = ({ allSystemsOk }) => {
  return (
    <div className='footer'>
      {/* <div className='container-footer'> */}
      <div className='footer-inner'>
        <div className='copyright'>
          <span>&#169; BlickSolutions GmbH</span>
        </div>
        <div className='status'>
          <span>Version {VERSION}</span>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default Footer;

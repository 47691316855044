import React, { useContext } from 'react';
import UserContext from '../../context/UserContext';
import { formatterEuro } from '../../includes/utils';
import { downloadInvoice } from '../../routes/order-routes';
// import http from '../../http-common';

const getDate = date => {
  let m = new Date(date);
  let dateString =
    ('0' + m.getUTCDate()).slice(-2) +
    '.' +
    ('0' + (m.getUTCMonth() + 1)).slice(-2) +
    '.' +
    m.getUTCFullYear();
  return dateString;
};

const OrdersTableRow = ({ doc, index, sendInvoice, removeItem }) => {
  const { activeUser } = useContext(UserContext);

  return (
    <div className='table-row'>
      <table className='table-order-row'>
        <tbody>
          <tr>
            <td className='col-15 text-center content'>
              {doc.customer.customerId}
            </td>
            <td className='col-10 text-center content'>{doc.name}</td>
            <td className='col-15 text-center content'>
              {doc.orderDate && getDate(doc.orderDate)}
            </td>

            <td className='col-10 text-center content'>{doc.invoiceNo}</td>

            <td className='col-15 text-right content'>
              {doc.net && formatterEuro.format(doc.net)}
            </td>

            <td className='col-10 text-right content'>
              {doc.tax && formatterEuro.format(doc.tax)}
            </td>

            <td className='col-15 text-right content'>
              {doc.gross && formatterEuro.format(doc.gross)}
            </td>

            <td className='col-5 text-right'>
              <div className='icon'>
                {
                  doc.invoiceDate && (
                    <span onClick={() => downloadInvoice(doc.invoiceNo)}>
                      <i className='fas fa-file-pdf'></i>
                    </span>
                  )
                  // : (
                  //   activeUser.user.role !== 'readOnly' && (
                  //     <span onClick={() => sendInvoice(doc._id)}>
                  //       <i className='far fa-paper-plane'></i>
                  //     </span>
                  //   )
                  // )
                }
              </div>
            </td>
            <td className='col-5 text-right'>
              {activeUser.user.role !== 'readOnly' && (
                <div onClick={e => removeItem(e, index)}>
                  <i className='far fa-times-circle icon'></i>
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTableRow;

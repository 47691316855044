import React, { useState, useContext } from 'react';
import UserContext from '../../context/UserContext';
// import Checkbox from './Checkbox';
import DeleteUserConfirmation from './DeleteUserConfirmation';
import EditUserCard from './EditUserCard';
import UserRole from './UserRole';

const UserTableRow = ({ user, users, index, setUsers }) => {
  const { activeUser } = useContext(UserContext);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [editUser, setEditUser] = useState(false);

  return (
    <div className='table-row'>
      <table className='table-order-row'>
        <tbody>
          <tr
            className={user._id === activeUser.user._id ? 'highlight bold' : ''}
          >
            <td className='col-30 content'> {user.name}</td>

            <td className='col-30 content'>{user.email}</td>

            <td className='col-30 content'>
              <UserRole user={user} />
            </td>
            <td className='col-5 text-center'>
              <div className='icon' onClick={() => setEditUser(true)}>
                <i className='far fa-edit fa-lg'></i>
              </div>
            </td>
            <td className='col-5 text-right'>
              <div
                className='icon'
                onClick={() => setShowDeleteConfirmation(true)}
              >
                <i className='far fa-times-circle'></i>
              </div>
              {showDeleteConfirmation && (
                <DeleteUserConfirmation
                  user={user}
                  setShowDeleteConfirmation={setShowDeleteConfirmation}
                  index={index}
                  users={users}
                  setUsers={setUsers}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {editUser && (
        <EditUserCard
          setUsers={setUsers}
          users={users}
          user={user}
          setEditUser={setEditUser}
          mode={activeUser.user._id === user._id ? 'activeUser' : 'editUser'}
        />
      )}
    </div>
  );
};

export default UserTableRow;

import React, { useState, useEffect } from 'react';
import { deepCopy } from '../../includes/utils';

const ProductIds = ({ productIds, deleteProductId }) => {
  return (
    <>
      {productIds.map((productId, index) => {
        return (
          <div className='product-id' key={index}>
            {productId}
            <div
              className='product-id-delete'
              onClick={() => deleteProductId(productId)}
            >
              <i className='fas fa-times'></i>
            </div>
          </div>
        );
      })}
    </>
  );
};

const Row = ({ productGroup, updateProductGroup, deleteProductGroup }) => {
  // const [myPG, setMyPG] = useState(deepCopy(productGroup));
  const [newProductId, setNewProductId] = useState('');

  // useEffect(() => {
  //   setMyPG(deepCopy(productGroup));
  // }, [productGroup]);

  const removeProductGroup = () => {
    deleteProductGroup(productGroup);
  };

  const deleteProductId = productId => {
    let newPG = deepCopy(productGroup);
    let myIds = newPG.shopifyProductIds;
    const index = myIds.indexOf(productId);
    if (index >= 0) {
      myIds.splice(index, 1);
    }
    updateProductGroup(newPG);
  };

  const addProductId = () => {
    if (newProductId === '') return;
    let newPG = deepCopy(productGroup);
    let mySet = new Set(newPG.shopifyProductIds);
    mySet.add(newProductId);
    newPG.shopifyProductIds = [...mySet];
    updateProductGroup(newPG);
    setNewProductId('');
  };

  const changeNewProductId = event => {
    setNewProductId(event.target.value);
  };

  return (
    <div className='product-group-row'>
      <div className='product-group-delete' onClick={removeProductGroup}>
        <div className='icon'>
          <i className='fas fa-times'></i>
        </div>
      </div>
      <div className='product-group'>
        <input
          type='text'
          className='product-group-input'
          value={productGroup.number}
          onChange={changeNewProductId}
          autoComplete='off'
        />
        {/* {productGroup.number} */}
      </div>
      <span className='product-ids w-100'>
        <ProductIds
          productIds={productGroup.shopifyProductIds}
          deleteProductId={deleteProductId}
        />

        {/* <ProductIdSelect productIds={productGroup.shopifyProductIds} /> */}
        {/* <i className='far fa-trash-alt'></i> */}
      </span>
      <div className='product-group-right-col'>
        <div className='product-group-add-field'>
          <input
            type='text'
            className='input-with-plus'
            value={newProductId}
            onChange={changeNewProductId}
            autoComplete='off'
          />
          <div className='add-button' onClick={addProductId}>
            <div className=''>
              <i className='fas fa-plus'></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditProductGroupTab = ({
  productGroups,
  updateProductGroup,
  addProductGroup,
  newPGNumber,
  changeNewPGNumber,
  deleteProductGroup,
}) => {
  // const [newPG, setNewPG] = useState('');

  return (
    <>
      <div className='product-group-header'>
        <div className='product-group-delete'></div>

        <div className='product-group'>Auftragsnummer</div>
        <div className='w-100'>Shopify Produkt-Ids</div>
        <div className='product-group-right-col'>
          Shopify Produkt-Id hinzufügen
        </div>
      </div>
      <div className='product-group-table'>
        {productGroups.map((productGroup, index) => {
          return (
            <Row
              key={index}
              productGroup={productGroup}
              updateProductGroup={updateProductGroup}
              deleteProductGroup={deleteProductGroup}
            />
          );
        })}
      </div>
      {/* <div className='product-group-row'> */}
      <div className='product-group-add-row'>
        <div className='product-group-add-field'>
          <input
            type='text'
            className='input-with-plus'
            value={newPGNumber}
            onChange={changeNewPGNumber}
            autoComplete='off'
          />
          <div className='add-button' onClick={addProductGroup}>
            <div className=''>
              <i className='fas fa-plus'></i>
            </div>
          </div>
        </div>
        <span>Neue Auftragsnummer anlegen</span>
      </div>
      {/* </div> */}
    </>
  );
};

export default EditProductGroupTab;

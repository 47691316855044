import React from 'react';
import { formatterEuro } from '../../includes/utils';

const SumTableRow = ({
  totalSums,
  downloadMonthlyInvoices,
  downloadMonthlyInvoicesCSV,
}) => {
  return (
    <div className='table-header'>
      <div className='totals-card'>
        <div className='totals-row'>
          <table className='table-order-row'>
            <tbody>
              <tr>
                <td className='col-50 text-center content'>
                  Summen gesamter Monat
                </td>

                {/* <td className='col-15 text-center content'></td>

                <td className='col-15 text-center content'></td> */}

                <td className='col-15 text-right content'>
                  {formatterEuro.format(totalSums.net)}
                </td>

                <td className='col-10 text-right content'>
                  {formatterEuro.format(totalSums.tax)}
                </td>

                <td className='col-15 text-right content'>
                  {formatterEuro.format(totalSums.gross)}
                </td>

                <td className='col-5 text-right'>
                  <div className='icon'>
                    <span onClick={() => downloadMonthlyInvoices()}>
                      <i className='fas fa-file-pdf'></i>
                    </span>
                  </div>
                </td>
                <td className='col-5 text-right'>
                  <div className='icon'>
                    <span onClick={() => downloadMonthlyInvoicesCSV()}>
                      <i className='fas fa-file-csv'></i>
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SumTableRow;

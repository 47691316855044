import React, { useContext, useState, useEffect } from 'react';
import UserContext from '../context/UserContext';
import EditUserCard from './users/EditUserCard';

const SidebarNav = ({ mode, setMode, users, setUsers, openOrders }) => {
  const { activeUser, setActiveUser } = useContext(UserContext);
  const [activeRole] = useState(activeUser && activeUser.user.role);
  const [editUser, setEditUser] = useState(false);
  const [openOrderText, setOpenOrderText] = useState('');

  const logOut = e => {
    sessionStorage.removeItem('auth-token');

    setActiveUser({
      token: undefined,
      user: undefined,
    });
  };

  useEffect(() => {
    let myText = '';
    switch (openOrders) {
      case 0:
        myText = 'Keine offenen Bestellungen';
        break;
      case 1:
        myText = 'Eine offene Bestellung';
        break;
      default:
        myText = `${openOrders} offene Bestellungen`;
    }
    setOpenOrderText(myText);
  }, [openOrders]);

  return (
    <div className='sidebar-nav'>
      <div className='sidebar-logo'>MEDIA PARTISANS</div>

      <div className='row h-24'>
        <div className='active-user'>
          <i className='far fa-user-circle fa-lg w-24'></i>
          <span className='ml-8'>
            <span className='highlight bold'>{activeUser.user.name}</span>
          </span>
        </div>
        <div className='sidebar-icons'>
          <div className='icon w-24' onClick={() => setEditUser(true)}>
            <i className='far fa-edit fa-lg'></i>
          </div>
          <div className='icon ml-8 w-24' onClick={logOut}>
            <i className='fas fa-sign-out-alt fa-lg'></i>
          </div>
        </div>
      </div>

      <div className='nav-block'>
        <div className='nav-row'>
          <div className='bold'>Dashboard</div>
        </div>
        <div className='nav-row'>
          <i className='fas fa-hourglass-start mr-16 w-24'></i>
          <div className='highlight-box'>
            {openOrderText}
            {/* {openOrders ? openOrders + ' offene ' : 'Keine offene '}
            {openOrders === 0 ? ' Bestellung' : ' Bestellungen'} */}
          </div>
        </div>
      </div>

      <div className='nav-block'>
        <div className='nav-row'>
          <div
            className={mode === 'orders' ? 'link highlight' : 'link'}
            onClick={() => setMode('orders')}
          >
            Bestellungen
          </div>
        </div>

        {activeRole === 'admin' && (
          <div className='nav-row'>
            <div
              className={mode === 'users' ? 'link highlight' : 'link'}
              onClick={() => setMode('users')}
            >
              Benutzer
            </div>
          </div>
        )}

        {activeRole === 'admin' && (
          <div className='nav-row'>
            <div
              className={mode === 'editData' ? 'link highlight' : 'link'}
              onClick={() => setMode('editData')}
            >
              Einstellungen
            </div>
          </div>
        )}
      </div>
      <div className='nav-block'>
        <div className='nav-row'>Links</div>
        <div className='nav-row'>
          <a target='#' href='https://mediapartisans-shop.myshopify.com/admin'>
            Shopify
          </a>
        </div>
        {/* <div className='nav-row'>
          <a href='/contact'>Kontakt</a>
        </div> */}
        <div className='nav-row'>
          <a target='#' href='https://mediapartisans-shop.de'>
            Webseite
          </a>
        </div>
      </div>
      {editUser && (
        <EditUserCard
          setUsers={setUsers}
          users={users}
          user={activeUser.user}
          setEditUser={setEditUser}
          mode='activeUser'
        />
      )}
    </div>
  );
};

export default SidebarNav;

import React from 'react';

const DataTabRow = ({
  modes,
  mode,
  changeMode,
  saveSuccess,
  commitChanges,
}) => {
  // const changeActiveFilter = newValue => {
  //   let myFilter = { ...activeFilter };
  //   myFilter.labelStatus = newValue;
  //   setActiveFilter(myFilter);
  // };

  return (
    <div className='table-tab-row'>
      <div className='table-tab'>
        {modes.map((myMode, index) => {
          return (
            <div
              key={index}
              className={mode === myMode ? 'tab tab-active' : 'tab'}
              onClick={changeMode}
              value={myMode}
              // onClick={() => changeActiveFilter(sts)}
            >
              <div className='capitalize'>{myMode}</div>
            </div>
          );
        })}
      </div>

      {/* {saveSuccess ? (
        <div className='save-success'>
          <i className='far fa-check-circle fa-2x'></i>
        </div>
      ) : (
        <div className='btn btn-primary mb-8' onClick={commitChanges}>
          Speichern
        </div>
      )} */}
    </div>
  );
};

export default DataTabRow;

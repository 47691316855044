import React from 'react';
import { EditDataTableRow } from './EditDataTableRow';

const EditEmailDataTab = ({ pD, changeData }) => {
  return (
    <>
      <EditDataTableRow
        label='Email-Text'
        path='emailText'
        value={pD.emailText}
        changeData={changeData}
        type='textarea'
      />
    </>
  );
};

export default EditEmailDataTab;

import React, { useState } from 'react';
import './App.css';
import LoginPage from './components/LoginPage';
import DashboardPage from './components/DashboardPage';
import Footer from './components/Footer';
import UserContext from './context/UserContext';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  const [activeUser, setActiveUser] = useState({
    token: undefined,
    user: undefined,
  });

  const [allSystemsOk] = useState(true);

  return (
    <Router>
      <UserContext.Provider
        value={{ activeUser: activeUser, setActiveUser: setActiveUser }}
      >
        <Switch>
          <Route path='/'>
            {activeUser.user ? (
              <div>
                <DashboardPage />
                <Footer allSystemsOk={allSystemsOk} />
              </div>
            ) : (
              <LoginPage />
            )}
          </Route>
        </Switch>
      </UserContext.Provider>
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';

const MonthSelect = ({ monthYear, setMonthYear }) => {
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const baseYear = 2021;

  useEffect(() => {
    let myMonths = [];
    for (let i = 0; i < 12; i++) {
      const date = new Date(baseYear, i, 1);
      const myMonth = date.toLocaleString('default', { month: 'long' });
      myMonths.push({ value: i, label: myMonth });
    }
    setMonths(myMonths);
    let myYears = [];
    for (let i = baseYear; i <= new Date().getFullYear(); i++) {
      myYears.push({ value: i, label: i });
    }
    setYears(myYears);
  }, []);

  const changeMonth = option => {
    let myMonthYear = { ...monthYear, month: option.value };
    setMonthYear(myMonthYear);
  };

  const changeYear = option => {
    let myMonthYear = { ...monthYear, year: option.value };
    setMonthYear(myMonthYear);
  };

  const arrowClosed = (
    <div className='arrow-closed'>
      <i className='fas fa-chevron-up'></i>
    </div>
  );
  const arrowOpen = (
    <div className='arrow-open'>
      <i className='fas fa-chevron-up'></i>
    </div>
  );

  return (
    <div className='button-row'>
      <Dropdown
        options={months}
        value={months[monthYear.month]}
        onChange={changeMonth}
        name='month'
        className='month'
        arrowClosed={arrowClosed}
        arrowOpen={arrowOpen}
      />
      <Dropdown
        options={years}
        value={years[monthYear.year - baseYear]}
        onChange={changeYear}
        name='year'
        arrowClosed={arrowClosed}
        arrowOpen={arrowOpen}
      />
    </div>
  );
};

export default MonthSelect;

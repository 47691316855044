import React, { useState, useEffect } from 'react';
import SidebarNav from './SidebarNav';
import OrdersTable from './orders/OrdersTable';
import UserTable from './users/UserTable';
import EditDataPage from './data/EditDataPage';
import { getPD, updatePD } from '../routes/pd-routes';

const getPermanentData = async () => {
  let myData = await getPD();
  if (myData) {
    if (myData.success === 'true') {
      return myData.pD;
    } else return false;
  } else {
    return false;
  }
};

const DashboardPage = () => {
  const [mode, setMode] = useState('orders');
  const [openOrders, setOpenOrders] = useState(0);
  const [pD, setPD] = useState({});

  useEffect(() => {
    const getPD = async () => {
      let myPD = await getPermanentData();
      if (myPD) setPD({ ...myPD });
    };
    getPD();
  }, []);

  const storePD = async newPD => {
    let myRes = await updatePD(newPD);
    if (myRes && myRes.success === 'true') {
      setPD(newPD);
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className='dashboard-wrapper'>
        <div className='container'>
          {mode === 'orders' && <OrdersTable setOpenOrders={setOpenOrders} />}
          {mode === 'users' && <UserTable />}
          {mode === 'editData' && (
            <EditDataPage pD={pD} setPD={setPD} storePD={storePD} />
          )}
          <SidebarNav mode={mode} setMode={setMode} openOrders={openOrders} />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;

import React from 'react';

const UserTableHeader = () => {
  return (
    <div className='table-header'>
      <div className='table-header-card'>
        <div className='table-header-row bold justify-content'>
          <table className='table-headings'>
            <tbody>
              <tr>
                <td className='col-30 content'>Name</td>

                <td className='col-30 content'>E-mail</td>

                <td className='col-30 content'>Rolle</td>

                <td className='col-10'>
                  {/* <i className='fas fa-user-times icon'></i> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default UserTableHeader;

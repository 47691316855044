import React, { useState } from 'react';
export const EditDataTableRow = ({
  label,
  value,
  path,
  changeData,
  type = 'text',
  placeholder = '',
  showHideIcon = false,
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  const toggleHidePassword = () => {
    let myHide = !hidePassword;
    setHidePassword(myHide);
  };
  return (
    <div className='table-data-row'>
      <div className='col-30 label'>
        {label}
        {showHideIcon && (
          <span onClick={toggleHidePassword} className='eye-icon'>
            <i className={hidePassword ? 'far fa-eye' : 'far fa-eye-slash'}></i>
          </span>
        )}
      </div>
      <div className='col-70'>
        {type === 'textarea' ? (
          <textarea
            className='data-input'
            rows='4'
            path={path}
            value={value}
            onChange={changeData}
          />
        ) : (
          // toggle between password and text depending on hidePassword
          <input
            type={hidePassword ? type : 'text'}
            className='data-input'
            path={path}
            value={value}
            onChange={changeData}
            placeholder={placeholder}
          />
        )}
      </div>
    </div>
  );
};
export default EditDataTableRow;

import React from 'react';

const LogosOnIndex = () => {
  return (
    <div className='card'>
      <div className='logo'>
        <div className='login-logo'></div>
        <div className='login-logo-text'>MEDIA PARTISANS</div>
      </div>
    </div>
  );
};

export default LogosOnIndex;

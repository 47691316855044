import http, { authHeader } from '../http-common';
import axios from 'axios';

export const getAllProductGroups = async () => {
  const headers = authHeader();
  let myRes = await axios.get(`${http.defaults.baseUrl}/productGroups`, {
    headers,
  });
  if (myRes.data.success === 'false') {
    console.log(myRes.data);
  }
  return myRes.data;
};

export const createProductGroup = async productGroup => {
  const headers = authHeader();
  const data = { number: productGroup };

  let myRes = await axios.post(`${http.defaults.baseUrl}/productGroup`, data, {
    headers,
  });
  if (myRes.data.success === 'false') {
    console.log(myRes.data);
  }
  return myRes.data;
};

export const updateProductGroup = async productGroup => {
  const headers = authHeader();
  const data = {
    _id: productGroup._id,
    shopifyProductIds: productGroup.shopifyProductIds,
    number: productGroup.number,
  };
  // return true;
  let myRes = await axios.put(`${http.defaults.baseUrl}/productGroup`, data, {
    headers,
  });
  if (myRes.data.success === 'false') {
    console.log(myRes.data);
  }
  return myRes.data;
};

export const deleteProductGroup = async productGroup => {
  const headers = authHeader();
  let myRes = await axios.delete(
    `${http.defaults.baseUrl}/productGroup?id=${productGroup._id}`,
    {
      headers,
    }
  );
  if (myRes.data.success === 'false') {
    console.log(myRes.data);
  }
  return myRes.data;
};

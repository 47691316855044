import React from 'react';
import { deleteUser } from '../../routes/user-routes';

const DeleteUserConfirmation = ({
  user,
  setShowDeleteConfirmation,
  index,
  users,
  setUsers,
  setMsg,
  setShowAlert,
}) => {
  const delUser = () => {
    setShowDeleteConfirmation(false);
    if (deleteUser(user)) {
      let myUsers = [...users];
      myUsers.splice(index, 1);
      setUsers(myUsers);
    }
  };

  return (
    <div className='pop-up-alert'>
      <div className='pop-up-header'>
        <div></div>
        <div
          className='icon close-pop-up'
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <i className='fas fa-times'></i>
        </div>
      </div>
      <div className='pop-up-body'>
        <div className='text-center'>
          <span className='highlight bold'>{user.name}</span> wirklich löschen?
        </div>
      </div>
      <div className='pop-up-footer'>
        <div className='pop-up-row'>
          <div></div>
          <div className='btn btn-primary' onClick={delUser}>
            Löschen
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserConfirmation;

import React from 'react';
import LogosOnIndex from './LogosOnIndex';
import SignInCard from './users/SignInCard';

const LoginPage = () => {
  return (
    <div className='wrapper'>
      <div className='container'>
        <div className='login-wrapper'>
          <LogosOnIndex />
          <SignInCard />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;

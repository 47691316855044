import axios from 'axios';
import { server_url } from './includes/constants';

export const axiosClient = axios.create({
  // local:
  // baseUrl: 'http://localhost:5020',

  // remote:
  // baseUrl: 'https://ginmasterv2.obc-client.de',
  baseUrl: server_url,
  timeout: 10000,
  headers: {
    'content-type': 'application/json',
  },
});

export default axiosClient;

export const authHeader = () => {
  let token = sessionStorage.getItem('auth-token');
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  return headers;
};

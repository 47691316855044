import React, { useState } from 'react';
import OrdersTableRow from './OrdersTableRow';
import DeleteConfirmation from '../DeleteConfirmation';
import SumTableRow from './SumTableRow';

const OrdersTableContent = ({
  docs,
  sendInvoice,
  removeOrder,
  toggleMarkForDownload,
  // totalSums,
  // activeFilter,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteConfirmationIndex, setDeleteConfirmationIndex] = useState(0);
  const [deleteConfirmationText, setDeleteConfirmationText] = useState('');

  const removeItem = (e, index) => {
    let wrapper = document.getElementById('orders-confirmation-wrapper');
    wrapper.style.top = `${
      e.target.getBoundingClientRect().y + window.scrollY
    }px`;

    let myOrderDate = new Date(docs[index].orderDate);
    let options = { dateStyle: 'short' };
    let orderDate = myOrderDate.toLocaleString('de-DE', options);
    let myText = `Bestellung von ${docs[index].customer.firstName} ${docs[index].customer.lastName} vom ${orderDate}`;
    setDeleteConfirmationText(myText);

    setShowDeleteConfirmation(true);
    setDeleteConfirmationIndex(index);
  };

  const deleteConfirmed = () => {
    removeOrder(docs[deleteConfirmationIndex]._id);
    setShowDeleteConfirmation(false);
  };

  return (
    <div className='table'>
      <div className='table-inner'>
        {docs &&
          docs.map((doc, index) => (
            <OrdersTableRow
              key={index}
              doc={doc}
              index={index}
              sendInvoice={sendInvoice}
              toggleMarkForDownload={toggleMarkForDownload}
              removeItem={removeItem}
            />
          ))}
      </div>
      <div id='orders-confirmation-wrapper'>
        {showDeleteConfirmation && (
          <DeleteConfirmation
            value={deleteConfirmationText}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            deleteConfirmed={deleteConfirmed}
          />
        )}
      </div>
    </div>
  );
};

export default OrdersTableContent;

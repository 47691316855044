// Functions to get permanent data form server (e.g. email-text etc.)
import http, { authHeader } from '../http-common';
import axios from 'axios';

export const getPD = async () => {
  const headers = authHeader();
  try {
    let myRes = await axios.get(`${http.defaults.baseUrl}/pd`, { headers });
    if (myRes) {
      return myRes.data;
    }
  } catch (error) {
    console.log("Couldn't get permanent data");
    return false;
  }
};

export const updatePD = async pD => {
  const headers = authHeader();
  const data = { newPD: pD };
  try {
    let myRes = await axios.put(`${http.defaults.baseUrl}/pd`, data, {
      headers,
    });
    if (myRes) {
      return myRes.data;
    }
  } catch (error) {
    console.log("Couldn't get permanent data");
    return false;
  }
};

// export const updateUser = async user => {
//   const headers = authHeader();
//   const data = {
//     name: user.name,
//     role: user.role,
//     email: user.email,
//     _id: user._id,
//     password: user.password,
//   };
//   let myRes = await axios.put(`${http.defaults.baseUrl}/user`, data, {
//     headers,
//   });
//   if (myRes.data.success === 'false') {
//     return myRes.data;
//   }
//   return myRes.data.user;
// };

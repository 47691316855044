import React, { useState } from 'react';
import EditUserCard from './EditUserCard';

const emptyUser = {
  name: '',
  email: '',
  role: 'readOnly',
};

const UserTableButtonRow = ({ users, setUsers }) => {
  const [editUser, setEditUser] = useState(false);
  return (
    <div className='table-button-row'>
      <div className='btn btn-primary' onClick={() => setEditUser(true)}>
        Neuer Benutzer
      </div>
      {editUser && (
        <EditUserCard
          setUsers={setUsers}
          users={users}
          user={emptyUser}
          setEditUser={setEditUser}
          mode='newUser'
        />
      )}
    </div>
  );
};

export default UserTableButtonRow;

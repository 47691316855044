import React, { useState, useEffect } from 'react';
import UserTableHeader from './UserTableHeader';
import UserTableButtonRow from './UserTableButtonRow';
import UserTableContent from './UserTableContent';
import { getAllUsers } from '../../routes/user-routes';

const UserTable = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      const myUsers = await getAllUsers();
      // console.log(myUsers);
      setUsers(myUsers);
      return myUsers;
    };

    getUsers();
  }, []);

  return (
    <div className='table-wrapper'>
      <UserTableButtonRow users={users} setUsers={setUsers} />
      <UserTableHeader />
      <UserTableContent users={users} setUsers={setUsers} />
    </div>
  );
};

export default UserTable;

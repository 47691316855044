import http, { authHeader } from '../http-common';
import axios from 'axios';

export const loginUser = async ({ password, name, setActiveUser }) => {
  try {
    let myRes = await http.post(`${http.defaults.baseUrl}/login`, {
      password: password,
      name: name,
    });
    if (myRes.status === 200) {
      setActiveUser({
        token: myRes.data.token,
        user: myRes.data.user,
      });

      sessionStorage.setItem('auth-token', myRes.data.token);
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllUsers = async () => {
  const headers = authHeader();
  let myRes = await axios.get(`${http.defaults.baseUrl}/users`, { headers });
  if (myRes.data.success === 'true') return myRes.data.users;
  else return [];
};

export const createUser = async user => {
  console.log(user);
  const headers = authHeader();
  const data = user;

  let myRes = await axios.post(`${http.defaults.baseUrl}/user`, user, {
    headers,
  });
  console.log(myRes);
  if (myRes.data.success === 'false') {
    return myRes.data;
  }
  return myRes.data.user;
};

export const updateUser = async user => {
  const headers = authHeader();
  const data = {
    name: user.name,
    role: user.role,
    email: user.email,
    _id: user._id,
    password: user.password,
  };
  let myRes = await axios.put(`${http.defaults.baseUrl}/user`, data, {
    headers,
  });
  if (myRes.data.success === 'false') {
    return myRes.data;
  }
  return myRes.data.user;
};

export const deleteUser = async user => {
  const headers = authHeader();
  const data = {
    _id: user._id,
  };

  // console.log({ data });
  // try {
  let myRes = await axios.post(`${http.defaults.baseUrl}/deleteUser`, data, {
    headers,
  });
  if (myRes.data.success === 'false') {
    return myRes.data;
  }
  return myRes.data.user;
  // return myRes.data;
  // } catch (error) {
  //   console.log(error);
  //   return false;
  // }
};

export const resetPassword = async user => {
  const headers = authHeader();
  const data = {
    _id: user._id,
    email: user.email,
  };

  console.log('resetting');
  try {
    let myRes = await axios.post(
      `${http.defaults.baseUrl}/resetPassword`,
      { data },
      { headers }
    );
    return myRes.data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

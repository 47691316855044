import React, { useState, useEffect } from 'react';
import DataTabRow from './DataTabRow';
import EditCompanyDataTab from './EditCompanyDataTab';
import EditInvoiceDataTab from './EditInvoiceDataTab';
import EditEmailDataTab from './EditEmailDataTab';
import EditServerDataTab from './EditServerDataTab';
import EditProductGroupTab from './EditProductGroupTab';
import { deepCopy } from '../../includes/utils';
import {
  getAllProductGroups,
  updateProductGroup,
  createProductGroup,
  deleteProductGroup,
} from '../../routes/productgroup-routes';

const modes = ['rechnung', 'email', 'firma', 'server', 'auftragsnummern'];

// const findPath = (ob, key) => {
//   const path = [];
//   const keyExists = obj => {
//     if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
//       return false;
//     } else if (obj.hasOwnProperty(key)) {
//       path.push(key);
//       return true;
//     } else if (Array.isArray(obj)) {
//       let parentKey = path.length ? path.pop() : '';

//       for (let i = 0; i < obj.length; i++) {
//         path.push(`${parentKey}[${i}]`);
//         const result = keyExists(obj[i], key);
//         if (result) {
//           return result;
//         }
//         path.pop();
//       }
//     } else {
//       for (const k in obj) {
//         path.push(k);
//         const result = keyExists(obj[k], key);
//         if (result) {
//           return result;
//         }
//         path.pop();
//       }
//     }
//     return false;
//   };

//   keyExists(ob);

//   return path;
// };

function assign(obj, keyPath, value, index) {
  let lastKeyIndex = keyPath.length - 1;
  for (var i = 0; i < lastKeyIndex; ++i) {
    let key = keyPath[i];
    if (!(key in obj)) {
      obj[key] = {};
    }
    obj = obj[key];
  }
  if (Array.isArray(obj[keyPath[lastKeyIndex]])) {
    obj[keyPath[lastKeyIndex]][index] = value;
  } else {
    obj[keyPath[lastKeyIndex]] = value;
  }
}

const EditDataPage = ({ pD, setPD, storePD }) => {
  const [myPD, setMyPD] = useState(deepCopy(pD));
  const [productGroups, setProductGroups] = useState([]);
  const [mode, setMode] = useState(modes[0]);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [newPGNumber, setNewPGNumber] = useState('');

  useEffect(() => {
    getProductGroups();
  }, []);

  const changeData = e => {
    let myPath = e.target.getAttribute('path').split('.');
    let myData = deepCopy(myPD);
    let myIndex = e.target.getAttribute('index');
    assign(myData, myPath, e.target.value, myIndex);
    setMyPD(myData);
  };

  const commitChanges = async () => {
    let myRes = await storePD(myPD);

    if (myRes) {
      setSaveSuccess(true);
      setTimeout(() => {
        setSaveSuccess(false);
      }, 3000);
    }
  };

  const changeMode = e => {
    // console.log(e.target.getAttribute('value'));
    setMode(e.currentTarget.getAttribute('value'));
  };

  // -------------------------------------------------------------------------
  // Functions for productGroup
  // -------------------------------------------------------------------------

  const getProductGroups = async () => {
    let myRes = await getAllProductGroups();
    // console.log(myRes);

    if (myRes.success === 'true') setProductGroups(myRes.productGroups);
  };

  const changeProductGroup = async pG => {
    // console.log(pG);
    let myRes = await updateProductGroup(pG);
    if (myRes.success === 'true') getProductGroups();
  };

  const addProductGroup = async () => {
    if (newPGNumber === '') return;
    let myRes = await createProductGroup(newPGNumber);
    if (myRes.success === 'true') {
      getProductGroups();
      setNewPGNumber('');
    }
  };

  const changeNewPGNumber = e => {
    setNewPGNumber(e.target.value);
  };

  const removeProductGroup = async pG => {
    console.log(pG);
    let myRes = await deleteProductGroup(pG);
    if (myRes.success === 'true') getProductGroups();
  };

  return (
    <div className='table-wrapper'>
      <DataTabRow
        modes={modes}
        mode={mode}
        changeMode={changeMode}
        saveSuccess={saveSuccess}
        commitChanges={commitChanges}
      />

      <div className='table-data'>
        <div className='table-data-card'>
          {mode === 'rechnung' && (
            <EditInvoiceDataTab pD={myPD} changeData={changeData} />
          )}
          {mode === 'email' && (
            <EditEmailDataTab pD={myPD} changeData={changeData} />
          )}
          {mode === 'firma' && (
            <EditCompanyDataTab pD={myPD} changeData={changeData} />
          )}
          {mode === 'server' && (
            <EditServerDataTab pD={myPD} changeData={changeData} />
          )}
          {mode === 'auftragsnummern' && (
            <EditProductGroupTab
              productGroups={productGroups}
              updateProductGroup={changeProductGroup}
              createProductGroup={addProductGroup}
              newPGNumber={newPGNumber}
              changeNewPGNumber={changeNewPGNumber}
              addProductGroup={addProductGroup}
              deleteProductGroup={removeProductGroup}
            />
          )}
          {mode !== 'auftragsnummern' && (
            <div className='save-button-row'>
              {saveSuccess ? (
                <div className='save-success'>
                  <i className='far fa-check-circle fa-2x'></i>
                </div>
              ) : (
                <div className='btn btn-primary mb-8' onClick={commitChanges}>
                  Speichern
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditDataPage;

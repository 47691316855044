import React from 'react';
import MonthYearSelect from './MonthYearSelect';

const OrdersTableHeader = ({
  activeFilter,
  setActiveFilter,
  monthYear,
  setMonthYear,
}) => {
  return (
    <div className='table-header'>
      <div className='table-header-card'>
        <div className='table-header-row flex-left pt-8'>
          <div className='bold'>Filter</div>
          <div
            className={
              activeFilter === 'open'
                ? 'btn btn-active'
                : 'btn btn-flat text-muted'
            }
            onClick={() => setActiveFilter('open')}
          >
            <div className='capitalize'> Offen </div>
          </div>
          <div
            className={
              activeFilter === 'archiv'
                ? 'btn btn-active'
                : 'btn btn-flat text-muted'
            }
            onClick={() => setActiveFilter('archiv')}
          >
            <div className='capitalize'> Ausgangsbuch </div>
          </div>
          <div
            className={
              activeFilter === 'alle'
                ? 'btn btn-active'
                : 'btn btn-flat text-muted'
            }
            onClick={() => setActiveFilter('alle')}
          >
            <div className='capitalize'> Alle </div>
          </div>
          <MonthYearSelect monthYear={monthYear} setMonthYear={setMonthYear} />
        </div>

        <div className='table-header-row bold justify-content'>
          <table className='table-headings'>
            <tbody>
              <tr>
                <td className='col-15 text-center content'>Kunden-Nr.</td>

                <td className='col-10 text-center content'>Order</td>

                <td className='col-15 text-center content'>Bestellt</td>

                <td className='col-10 text-center content'>Re.-Nr.</td>

                <td className='col-15 text-right content pr-8'>Netto</td>

                <td className='col-10 text-right content pr-8'>USt.</td>

                <td className='col-15 text-right content pr-8'>Brutto</td>

                <td className='col-10'></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default OrdersTableHeader;

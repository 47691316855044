import React from 'react';

const AdditionalInfo = ({ addInfo }) => {
  return (
    <div className='pop-up-row info'>
      <div className='mr-16'>
        <i className='fas fa-exclamation fa-2x'></i>
      </div>
      <div>{addInfo}</div>
    </div>
  );
};

const DeleteConfirmation = ({
  value,
  addInfo,
  setShowDeleteConfirmation,
  deleteConfirmed,
  deletable = true,
}) => {
  return (
    <div className='pop-up-alert'>
      <div className='pop-up-header'>
        <div></div>
        <div
          className='icon close-pop-up'
          onClick={() => setShowDeleteConfirmation(false)}
        >
          <i className='fas fa-times'></i>
        </div>
      </div>
      <div className='pop-up-body'>
        {addInfo && (
          <div className='pop-up-row'>
            <AdditionalInfo addInfo={addInfo} />
          </div>
        )}
        {deletable && (
          <div className='pop-up-row'>
            <div className='text-center w-100'>
              <span>
                <span className='highlight bold'>{value}</span> wirklich
                löschen?
              </span>
            </div>
          </div>
        )}
      </div>
      <div className='pop-up-footer'>
        <div className='pop-up-row'>
          <div
            className='btn btn-primary'
            onClick={() => {
              setShowDeleteConfirmation(false);
            }}
          >
            Abbrechen
          </div>
          <div
            className={deletable ? 'btn' : 'btn disabled'}
            onClick={deletable ? deleteConfirmed : () => {}}
          >
            Löschen
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmation;

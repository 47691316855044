import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../context/UserContext';

import RoleSelect from './RoleSelect';
import {
  createUser,
  updateUser,
  resetPassword,
} from '../../routes/user-routes';

const EditUserCard = ({
  setUsers,
  users,
  user,
  setEditUser,
  mode,
  setMsg,
  setShowAlert,
}) => {
  const { activeUser, setActiveUser } = useContext(UserContext);

  const [myUser, setMyUser] = useState({
    ...user,
  });
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [heading, setHeading] = useState('');
  useEffect(() => {
    switch (mode) {
      case 'newUser':
        setHeading(
          <div>
            <span className='highlight'>Neuen Benutzer</span> anlegen
          </div>
        );
        break;
      default:
        setHeading(
          <div>
            <span className='highlight'>{myUser.name}</span> bearbeiten
          </div>
        );
    }
  }, [mode, myUser.name]);

  const passwordFields = (
    <div>
      <div className='pop-up-row'>
        <span className='bold'>Neues Passwort</span>
        <input
          type='password'
          id='editPassword'
          name='password'
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
        />
      </div>
      <div className='pop-up-row'>
        <span className='bold'>Passw. bestätigen</span>
        <input
          type='password'
          id='editPasswordCheck'
          name='passwordcheck'
          value={newPasswordCheck}
          onChange={e => setNewPasswordCheck(e.target.value)}
        />
      </div>
    </div>
  );

  const logOut = () => {
    sessionStorage.removeItem('auth-token');

    setActiveUser({
      token: undefined,
      user: undefined,
    });
  };

  const changeUserData = e => {
    let editUser = { ...myUser };
    editUser[e.target.name] = e.target.value;
    setMyUser(editUser);
  };

  const changeRole = option => {
    let editUser = { ...myUser };
    editUser.role = option.value;
    setMyUser(editUser);
  };

  const storeUser = async () => {
    const isEmpty = str => !str.trim().length;

    const setRedBorder = input => {
      input.classList.add('red-border');
      setTimeout(() => input.classList.remove('red-border'), 3000);
    };

    if (isEmpty(myUser.name) || isEmpty(myUser.email)) {
      if (isEmpty(myUser.name)) {
        let input = document.getElementById('editUserName');
        setRedBorder(input);
      } else {
        let input = document.getElementById('editUserEmail');
        setRedBorder(input);
      }
      return;
    }

    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(myUser.email)) {
      setRedBorder(document.getElementById('editUserEmail'));
      return;
    }

    if (!isEmpty(newPassword)) {
      if (newPassword !== newPasswordCheck) {
        setRedBorder(document.getElementById('editPasswordCheck'));
        return;
      }
    }

    let updatedUser = { ...myUser, password: newPassword };
    let newUser;

    // let myUsers = [...users];
    if (mode === 'editUser' || mode === 'activeUser') {
      // Nutzer bearbeiten

      newUser = await updateUser(updatedUser);

      if (newUser.success === 'false') {
        if (newUser.error === 'Name already in use') {
          setRedBorder(document.getElementById('editUserName'));
        }
        return;
      } else {
        updateUserTable(users, newUser, mode);
      }
    } else {
      // Neuen Nutzer anlegen
      newUser = await createUser(myUser, setMsg, setShowAlert);
      if (newUser.success === 'false') {
        if (newUser.error === 'Name already in use') {
          setRedBorder(document.getElementById('editUserName'));
        }
        return;
      } else {
        updateUserTable(users, newUser, mode);
      }
    }
    setEditUser(false);
    if (mode === 'activeUser') {
      logOut();
    }
  };

  const updateUserTable = (users, newUser, mode) => {
    if (!users) return;
    let myUsers = [...users];
    let index = myUsers.findIndex(elem => elem.id === newUser.id);
    switch (mode) {
      case 'activeUser':
      case 'editUser':
        myUsers.splice(index, 1, newUser);
        break;
      default:
        myUsers.push(newUser);
        setUsers(myUsers);
    }
    setUsers(myUsers);
  };

  const sendNewPassword = () => {
    resetPassword(user);
    setEditUser(false);
  };

  const activeUserInfo = (
    <div className='pop-up-row info'>
      <div className='mr-16'>
        <i className='fas fa-exclamation fa-2x'></i>
      </div>
      <div>
        Du bearbeitest den aktiven Benutzer.
        <br />
        Nach Klick auf "Speichern" wirst Du zur Anmeldeseite geleitet.
      </div>
    </div>
  );

  return (
    <div id='overlay'>
      <div className='modal'>
        <div className='modal-header'>
          <div className='modal-headline'>
            <i className='far fa-user-circle fa-lg'></i>
            <span className='ml-8'>
              <span className='bold'>{heading}</span>
            </span>
          </div>
          <div className='icon close-pop-up' onClick={() => setEditUser(false)}>
            <i className='fas fa-times'></i>
          </div>
        </div>
        <div className='modal-body'>
          {mode === 'activeUser' && activeUserInfo}
          <div className='pop-up-row'>
            <span className='bold'>Name</span>
            <input
              type='text'
              id='editUserName'
              name='name'
              value={myUser.name}
              onChange={changeUserData}
              autoComplete='off'
            />
          </div>
          <div className='pop-up-row'>
            <span className='bold'>E-mail</span>
            <input
              type='email'
              id='editUserEmail'
              name='email'
              value={myUser.email}
              onChange={changeUserData}
              autoComplete='off'
            />
          </div>
          {activeUser.user.role === 'admin' && (
            <div className='pop-up-row'>
              <span className='bold'>Rolle</span>
              <RoleSelect
                value={user && user.role}
                name='role'
                onChange={changeRole}
              />
            </div>
          )}
          {mode === 'activeUser' && passwordFields}
        </div>
        <div className='modal-footer'>
          <div className='pop-up-row'>
            {mode === 'editUser' ? (
              <small className='cursor-pointer' onClick={sendNewPassword}>
                Neues Passwort senden
              </small>
            ) : (
              <div></div>
            )}

            <div
              className='btn btn-primary'
              onClick={() => {
                storeUser();
                // deleteLabel(code, index, setDocs, docs, setMsg, setShowAlert);
              }}
            >
              Speichern
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  // <div
  //   dangerouslySetInnerHTML={{ __html: '<p>First &middot; Second</p>' }}
  // ></div>;
};

export default EditUserCard;

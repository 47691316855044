import React from 'react';
import { EditDataTableRow } from './EditDataTableRow';

const EditInvoiceDataTab = ({ pD, changeData }) => {
  return (
    <>
      <EditDataTableRow
        label='Rechnungs-Nummer'
        path='invoiceNoText'
        value={pD.invoiceNoText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Rechnungs-Datum'
        path='invoiceDateText'
        value={pD.invoiceDateText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Leistungs-Datum'
        path='fulfillmentDateText'
        value={pD.fulfillmentDateText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Intro'
        path='invoiceIntro'
        value={pD.invoiceIntro}
        changeData={changeData}
        type='textarea'
      />

      {pD.invoiceTableHeader.map((entry, index) => {
        return (
          <EditDataTableRow
            key={index}
            label={`Tabelle ${index + 1}. Spalte`}
            path='invoiceTableHeader'
            value={pD.invoiceTableHeader[index]}
            changeData={changeData}
          />
        );
      })}
      <EditDataTableRow
        label='Summe Produkt-Tabelle'
        path='productTableTotalText'
        value={pD.productTableTotalText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='1. Steuer-Betrag'
        path='taxText'
        value={pD.taxText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='2. Steuer-Betrag'
        path='secondTaxText'
        value={pD.secondTaxText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Versandkosten'
        path='shippingPriceText'
        value={pD.shippingPriceText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Brutto-Betrag'
        path='grossTotalText'
        value={pD.grossTotalText}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Outro'
        path='invoiceOutro'
        value={pD.invoiceOutro}
        changeData={changeData}
        type='textarea'
      />
    </>
  );
};

export default EditInvoiceDataTab;

import React from 'react';

const UserRole = ({ user }) => {
  let myRole;
  switch (user.role) {
    case 'admin':
      myRole = 'Administrator';
      break;
    case 'readWrite':
      myRole = 'Lesen & Schreiben';
      break;

    default:
      myRole = 'Nur Lesen';
  }
  return <div>{myRole}</div>;
};

export default UserRole;

import React from 'react';
import EditDataTableRow from './EditDataTableRow';

const EditCompanyDataTab = ({ pD, changeData }) => {
  return (
    <>
      <EditDataTableRow
        label='Firmen-Name'
        // id='name'
        path='company.name'
        value={pD.company.name}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Straße'
        path='company.address.street'
        // id='street'
        value={pD.company.address.street}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Hausnummer'
        path='company.address.number'
        value={pD.company.address.number}
        changeData={changeData}
      />
      <EditDataTableRow
        label='PLZ'
        path='company.address.code'
        value={pD.company.address.code}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Ort'
        path='company.address.city'
        value={pD.company.address.city}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Telefon'
        path='company.tel'
        value={pD.company.tel}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Email'
        path='company.email'
        value={pD.company.email}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Homepage'
        path='company.web'
        value={pD.company.web}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Handelsregister'
        path='company.legalRegister'
        value={pD.company.legalRegister}
        changeData={changeData}
      />
      <EditDataTableRow
        label='HReg-Nummer'
        path='company.legalRegNo'
        value={pD.company.legalRegNo}
        changeData={changeData}
      />
      <EditDataTableRow
        label='USt.-ID'
        path='company.salesTaxNo'
        value={pD.company.salesTaxNo}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Steuer-Nummer'
        path='company.taxNo'
        value={pD.company.taxNo}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Geschäftsführung'
        path='company.ceo'
        value={pD.company.ceo}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Buchungskreis'
        path='company.bookingCircle'
        value={pD.company.bookingCircle}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Sachkontonummer'
        path='company.groupAccountNo'
        value={pD.company.groupAccountNo}
        changeData={changeData}
      />
    </>
  );
};

export default EditCompanyDataTab;

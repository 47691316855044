import React from 'react';
import EditDataTableRow from './EditDataTableRow';

const EditServerDataTab = ({ pD, changeData }) => {
  return (
    <>
      <EditDataTableRow
        label='FTP-Server'
        placeholder='meinServer.de'
        path='ftpServer'
        value={pD.ftpServer}
        changeData={changeData}
      />
      <EditDataTableRow
        label='FTP-User'
        path='ftpUser'
        value={pD.ftpUser}
        changeData={changeData}
      />
      <EditDataTableRow
        label='FTP-Passwort'
        path='ftpPassword'
        value={pD.ftpPassword}
        changeData={changeData}
        type='password'
        showHideIcon={true}
      />
      <EditDataTableRow
        label='Email-Server'
        path='emailServer'
        value={pD.emailServer}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Email-Absender'
        path='emailSendFromAddress'
        value={pD.emailSendFromAddress}
        changeData={changeData}
      />
      <EditDataTableRow
        label='Email-Passwort'
        path='emailPassword'
        value={pD.emailPassword}
        changeData={changeData}
        type='password'
        showHideIcon={true}
      />
    </>
  );
};

export default EditServerDataTab;

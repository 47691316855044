import React from 'react';

const Pagination = ({ activePage, totalPages, setActivePage }) => {
  const changePage = amount => {
    let newPage = activePage + amount;
    if (newPage < 0) newPage = 0;
    if (newPage > totalPages - 1) newPage = totalPages - 1;
    setActivePage(newPage);
  };
  return (
    <div className='pagination mt-16'>
      <div className='icon' onClick={() => changePage(-1)}>
        <i className='fas fa-chevron-left fa-lg'></i>
      </div>
      <div className='ml-8 mr-8 pagination-pages'>
        <span className='highlight bold'>{activePage + 1}</span>
        <span className='text-muted'> / {totalPages}</span>
      </div>
      <div className='icon' onClick={() => changePage(1)}>
        <i className='fas fa-chevron-right fa-lg'></i>
      </div>
    </div>
  );
};

export default Pagination;
